<template>
  <v-container>
    <v-form ref="form" :disabled="submitted" fast-fail @submit.prevent="addDocumentation">
      <div v-if="dateMsg">
        <p class="text-red">Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums</p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled"
            :rules="dateRule"
            v-model="dateOfProcedure"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Datum der Maßnahme"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-radio-group :readonly="disabled" v-model="feedback.status">
        <v-radio
          label="keine Rückmeldung erforderlich"
          v-bind:value="false"
        ></v-radio>
        <v-radio label="Rückmeldung erforderlich" v-bind:value="true"></v-radio>
      </v-radio-group>

      <v-textarea
        v-show="feedback.status == true"
        :readonly="disabled"
        v-model="feedback.content"
        label="Fragen und Anmerkungen an andere Versorger"
      ></v-textarea>

      <v-row>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Parkinson Nurse"
            value="parknurse"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Facharzt"
            value="doctor"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Tele Nurse"
            value="telenurse"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Pflegedienst"
            value="careservice"
          ></v-checkbox>
        </v-col>
        <v-col>
          <v-checkbox
            v-show="feedback.status"
            v-model="feedback.recipients"
            label="Anderer externer Versorger"
            value="external"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-text-field
        :readonly="disabled"
        v-model="externalName"
        v-show="feedback.status && feedback.recipients.includes('external')"
        label="Externer Versorger"
      ></v-text-field>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import VueJWTDecode from "vue-jwt-decode";

export default {
  name: "FeedbackCareTeam",

  props: {
    document_data: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title:
        "Rückmeldung an das Versorgungsteam nach telefonischer Konsultation",
      type: "feedback_careteam",
      billableProcedures: ["57203A06"],
      feedback: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "feedback",
          ).value
        : {
            status: false,
            content: "",
            recipients: [],
            home_visit: { needed: false, reason: "" },
            specialist_visit: false,
          },
      dateOfProcedure: this.document_data
        ? this.document_data.treatment_date
        : "",
      externalName: "",
      disabled: this.disabled_field,
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      this.$refs.form.validate().then(
        (status) => {
          if (status.valid === true) {
            this.submitted = true
            const token = this.keycloak.token;
            const decoded_token = VueJWTDecode.decode(token);
            let user_name = decoded_token.preferred_username;

            let patient_id = this.$route.params.id.split("&", 1)[0];

            let dischargeLetterPath = undefined;

            let json_payload = {
              user_id: user_name,
              patient_id: patient_id,
              treatment_date: this.dateOfProcedure,
              documentation_date: new Date().toISOString(),
              treamtent_type: this.type,
              billable_procedures: this.billableProcedures,
              title: this.title,
              content: [
                { property: "reasons_of_call", value: this.reasonsOfCall },
                { property: "duration", value: this.duration },
                { property: "feedback", value: this.feedback },
              ],
            };

            if (this.dischargeLetter !== undefined && this.dischargeLetter.length > 0) {
              let blob = this.dischargeLetter[0];
              let newFile = new File(
                [blob],
                "DischargeLetter_" +
                  patient_id +
                  "_" +
                  Math.floor(new Date().getTime() / 1000) +
                  ".pdf",
                { type: blob.type },
              );
              let formData = new FormData();
              formData.append("file", newFile);

              this.$axios
                .get("/upload/", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                })
                .then(
                  (response) => {
                    if ("error" in response.data) {
                      console.log(response.data);
                      return;
                    } else {
                      console.log("File Upload successfull");
                      console.log(response.data.success.path);
                      dischargeLetterPath = response.data.success.path;
                    }
                    // console.log("TreatmentDocument created")
                    // this.$emit('close-dialog');
                  },
                  (error) => {
                    console.log(error);
                  },
                )
                .then(() => {
                  this.reasonsOfCall.hospitalization.discharge_letter_pdf =
                    dischargeLetterPath;

                  this.$axios
                    .post("/treatment_documentation/", json_payload, {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    })
                    .then(
                      (response) => {
                        console.log(response);
                        if ("success" in response.data) {
                          console.log(response.data);
                          this.$emit("close-dialog");
                        } else {
                          console.log(response.data);
                        }
                      },
                      () => {
                        console.log("TreatmentDocument not created");
                      },
                    );
                });
            } else {
              this.$axios
                .post("/treatment_documentation/", json_payload, {
                  headers: {
                    "Content-Type": "application/json",
                  },
                })
                .then(
                  (response) => {
                    console.log(response);
                    if ("success" in response.data) {
                      console.log(response.data);
                      this.$emit("close-dialog");
                    } else {
                      console.log(response.data);
                    }
                  },
                  () => {
                    console.log("TreatmentDocument not created");
                  },
                );
            }
          }
        },
        () => {},
      );
    },

    clearInput: function() {
      this.dateOfProcedure = ""
      this.dateMsg = true
    }
  },

  mounted() {
    console.log(this.dischargeLetterPDF);
  },
};
</script>
