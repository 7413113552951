import VueJWTDecode from "vue-jwt-decode";
import axios from "axios";

export default {
  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  },

  zip(array1, array2) {
    let zipped = [];

    for (let i = 0; i < array1.length; i++) {
      zipped.push([array1[i], array2[i]]);
    }

    return zipped;
  },

  formatDateStringToGermanDate(string) {
    return new Date(string).toLocaleString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  },

  formatDateStringToGermanDateTime(string) {
    return new Date(string).toLocaleString("de-DE", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  },

  getTimeFromDatetime(string) {
    if (string == undefined) {
      return;
    }
    console.log(string);
    let time = string.split("T")[1];
    console.log(time);
    return time.slice(0, -4);
  },

  getUserInfoFromToken(token) {
    const decoded_token = VueJWTDecode.decode(token);
    let first_name = decoded_token.given_name;
    let last_name = decoded_token.family_name;
    let user_name = decoded_token.preferred_username;
    let roles = decoded_token.resource_access.ppr.roles;

    let userInfo = {
      first_name: first_name,
      last_name: last_name,
      user_name: user_name,
      roles: roles,
    };

    return userInfo;
  },

  checkForRole(token, role) {
    const decoded_token = VueJWTDecode.decode(token);
    console.log(decoded_token);
    let roles = decoded_token.resource_access.ppr.roles;

    console.log(roles);

    return roles.includes(role);
  },

  getUserInfoForAllUsers(token) {
    return new Promise((resolve, reject) => {
      // Simulate an asynchronous operation (e.g., fetching data)
      console.warn("getUserInfoForAllUsers function is called");

      axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      axios.get("/get_users/1").then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          console.error(error);
          reject(error);
        },
      );
    });
  },
};
