<template>
    <v-container>
      <v-form ref="form" :disabled="submitted" fast-fail @submit.prevent="addDocumentation">
        <div v-if="dateMsg">
          <p class="text-red">Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums</p>
        </div>
        <v-row>
          <v-col cols="3">
            <v-text-field
              :readonly="disabled"
              :rules="dateRule"
              v-model="dateOfProcedure"
              type="date"
              :max="new Date().toISOString().slice(0, -14)"
              label="Datum"
              @keydown="clearInput()"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-text-field
          :readonly="disabled"
          v-model="duration"
          :rules="durationRules"
          label="Dauer der Maßnahme (HH:MM)"
        ></v-text-field>

        <v-label class="text-left"
          >Art des Ausschlusses</v-label
        >
        <v-radio-group :readonly="disabled" v-model="exclusionType">
          <v-radio label="Drop out" value="drop_out"></v-radio>
          <v-radio label="regulärer Ausschluss" value="regular"></v-radio>
        </v-radio-group>

        <v-textarea
          :readonly="disabled"
          v-model="annotation"
          label="Anmerkungen (optional)"
        ></v-textarea>
  
        <v-file-input
          v-show="!disabled"
          v-model="studyExclusionFile"
          :rules="fileRules"
          accept=".pdf"
          label="PDF-Dokument hochladen"
        ></v-file-input>
  
        <v-expansion-panels v-if="filePDF !== 'Not provided'">
          <v-expansion-panel>
            <v-expansion-panel-title>
              <v-col class="text-left">PDF-Upload</v-col>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <vue-pdf-embed v-show="filePDF" :source="fileBlob" />
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
  
        <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
      </v-form>
    </v-container>
  </template>
  
  <script>
  import VueJWTDecode from "vue-jwt-decode";
  import VuePdfEmbed from "vue-pdf-embed";
  
  export default {
    name: "StudyExclusion",
  
    components: {
      VuePdfEmbed,
    },
  
    props: {
      document_data: Object,
      disabled_field: Boolean,
    },
  
    data: function () {
      return {
        title: "Studienausschluss",
        type: "study_exclusion",
        filePDF: this.document_data
          ? this.document_data.content.find(
              (item) => item.property === "studyExclusionFile",
            ).value
          : "Not provided",
        fileBlob: undefined,
        studyExclusionFile: undefined,
        fileRules: [
          (value) => {
            return (
              !value ||
              !value.length ||
              value[0].size < 5 * 1024 * 1024 ||
              "Datei darf nicht größer als 5 MB sein!"
            );
          },
          (value) => {
            if (value) return true;
  
            return "Sie müssen eine Datensichtung hochladen";
          },
        ],
        dateOfProcedure: this.document_data ? this.document_data.treatment_date : "",
        duration: this.document_data
          ? this.document_data.content.find(
              (item) => item.property === "duration",
            ).value
          : "",
        exclusionType: this.document_data
          ? this.document_data.content.find(
              (item) => item.property === "exclusionType",
            ).value
          : "",
        annotation: this.document_data
          ? this.document_data.content.find(
              (item) => item.property === "annotation",
            ).value
          : "",
        durationRules: [
          (value) => {
            if (value) return true;
  
            return "Sie müssen eine Dauer angeben";
          },
          (value) => {
            if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
              return true;
  
            return "Dauer muss im Format HH:MM angegeben werden";
          },
        ],
        billableProcedures: ["A05"],
        disabled: this.disabled_field,
        notEmptyRule: [
          (value) => {
            if (value) return true;
  
            return "Dies ist ein Pflichtfeld";
          },
        ],
        dateRule: [
          (value) => {
            if (value) {
              this.dateMsg = false;
              return true;
            }

            return "Die Datumseingabe ist ein Pflichtfeld";
          },
        ],
        submitted: false,
        dateMsg: false,
      };
    },
  
    methods: {
      addDocumentation: function () {
        this.$refs.form.validate().then(
          (status) => {
            if (status.valid === true) {
              this.submitted = true
              const token = this.keycloak.token;
              const decoded_token = VueJWTDecode.decode(token);
              let user_name = decoded_token.preferred_username;
  
              let patient_id = this.$route.params.id.split("&", 1)[0];
              console.log(user_name);
              console.log(patient_id);
  
              let studyExclusionFilePath = undefined;
  
              if (this.studyExclusionFile !== undefined && this.studyExclusionFile.length > 0) {
                let blob = this.studyExclusionFile[0];
                let newFile = new File(
                  [blob],
                  "StudyExclusion_" +
                    patient_id +
                    "_" +
                    Math.floor(new Date().getTime() / 1000) +
                    ".pdf",
                  { type: blob.type },
                );
                let formData = new FormData();
                formData.append("file", newFile);
  
                this.$axios
                  .post("/upload/", formData, {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  })
                  .then(
                    (response) => {
                      if ("error" in response.data) {
                        console.log(response.data);
                        return;
                      } else {
                        console.log("File Upload successfull");
                        console.log(response.data.success.path);
                        studyExclusionFilePath = response.data.success.path;
                      }
                      // console.log("TreatmentDocument created")
                      // this.$emit('close-dialog');
                    },
                    (error) => {
                      console.log(error);
                    },
                  )
                  .then(() => {
                    let json_payload = {
                      user_id: user_name,
                      patient_id: patient_id,
                      treatment_date: this.dateOfProcedure,
                      documentation_date: new Date().toISOString(),
                      treamtent_type: this.type,
                      billable_procedures: this.billableProcedures,
                      title: this.title,
                      content: [
                        { property: "duration", value: this.duration },
                        { property: "exclusionType", value: this.exclusionType },
                        { property: "annotation", value: this.annotation },
                        { property: "studyExclusionFile", value: studyExclusionFilePath },
                      ],
                    };
  
                    console.log(json_payload);
  
                    this.$axios
                      .post("/treatment_documentation/", json_payload, {
                        headers: {
                          "Content-Type": "application/json",
                        },
                      })
                      .then(
                        (response) => {
                          console.log(response);
                          if ("success" in response.data) {
                            console.log(response.data);
                            this.$emit("close-dialog");
                          } else {
                            console.log(response.data);
                          }
                        },
                        () => {
                          console.log("TreatmentDocument not created");
                        },
                      );
                  });
              } else {
                let json_payload = {
                  user_id: user_name,
                  patient_id: patient_id,
                  treatment_date: this.dateOfProcedure,
                  documentation_date: new Date().toISOString(),
                  treamtent_type: this.type,
                  billable_procedures: this.billableProcedures,
                  title: this.title,
                  content: [
                    { property: "duration", value: this.duration },
                    { property: "exclusionType", value: this.exclusionType },
                    { property: "annotation", value: this.annotation },
                    { property: "studyExclusionFile", value: "Not provided" },
                  ],
                };
  
                console.log(json_payload);
  
                this.$axios
                  .post("/treatment_documentation/", json_payload, {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  })
                  .then(
                    (response) => {
                      console.log(response);
                      if ("success" in response.data) {
                        console.log(response.data);
                        this.$emit("close-dialog");
                      } else {
                        console.log(response.data);
                      }
                    },
                    () => {
                      console.log("TreatmentDocument not created");
                    },
                  );
              }
            }
          },
          () => {},
        );
      },
  
      fetchPDFFile: function () {
        fetch(process.env.VUE_APP_BACKEND_BASE_URL + this.filePDF, {
          headers: {
            Authorization: "Bearer " + this.keycloak.token,
          },
        })
          .then((response) => response.blob())
          .then((blob) => {
            var that = this; //the magic happens
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64data = reader.result;
              that.fileBlob = base64data;
            };
          })
          .catch((error) => console.error("Error:", error));
      },
  
      clearInput: function() {
        this.dateOfProcedure = ""
        this.dateMsg = true
      }
    },
  
    watch: {
      filePDF: {
        immediate: true,
        handler(newVal) {
          // React to route parameter change
          // For example, fetch new data based on the new parameter
          console.log(newVal);
          if (newVal != undefined) {
            this.fetchPDFFile();
          }
          console.log(this.fileBlob);
        },
      },
    },
  
    mounted() {
      console.log(this.disabled);
    },
  };
  </script>
  