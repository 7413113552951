<script setup>
import UPDRSDetailedView from "./UPDRSDetailedDisplay.vue";
</script>

<template>
  <v-sheet
    border="md"
    rounded="lg"
    width="100%"
    class="my-sheet pa-4 text-left mx-auto mb-2 mr-2"
    @click="detailedView = true"
  >
    <h2 class="text-h5 mb-6">MDS-UPDRS</h2>
    <div v-if="hasData">
      <v-row class="mt-n2">
        <v-col>
          <div ref="part_1">
            <canvas height="50" @click="clicked_part = 'Part 1'; title_of_selected_part='Teil I: Erfahrungen des täglichen Lebens - nicht-motorische Aspekte'"></canvas>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-n2">
        <v-col>
          <div ref="part_2">
            <canvas height="50" @click="clicked_part = 'Part 2'; title_of_selected_part='Teil II: Motorische Aspekte bei Erfahrungen des täglichen Lebens'"></canvas>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-n2">
        <v-col>
          <div ref="part_3">
            <canvas height="50" @click="clicked_part = 'Part 3'; title_of_selected_part='Teil III: Motorische Untersuchung'"></canvas>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-n2">
        <v-col>
          <div ref="part_4">
            <canvas height="50" @click="clicked_part = 'Part 4'; title_of_selected_part='Teil IV: Motorische Komplikationen'"></canvas>
          </div>
        </v-col>
      </v-row>
    </div>
    <div v-else>Daten nicht vorhanden</div>
  </v-sheet>

  <v-dialog v-model="detailedView" persistent width="1024">
    <v-card>
      <v-card-title>
        <span class="text-h5">MDS-UPDRS Teilvisualisierung</span>
      </v-card-title>
      <v-card-text>
          <UPDRSDetailedView :selected="clicked_part" :title_of_selected="title_of_selected_part"></UPDRSDetailedView>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
            <v-btn
              color="green-darken-1"
              variant="text"
              @click="detailedView = false"
            >
              Schließen
            </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "UPDRSDisplay",

  data: () => ({
    updrs_data: {},
    graph: null,
    patient_id: "",
    detailedView: false,
    hasData: false,
    plot_1: null,
    plot_2: null,
    plot_3: null,
    plot_4: null,
    clicked_part: 'Part 1',
    title_of_selected_part: 'Teil I: Erfahrungen des täglichen Lebens - nicht-motorische Aspekte'
  }),

  methods: {
    getUPDRSData: function () {
      this.$axios
        .get("/get_updrs/" + this.patient_id)
        .then(
          (response) => {
            console.log(response.data);
            this.updrs_data = response.data;
            if(!this.helpers.isEmpty(this.updrs_data)) {
              this.hasData = true
            }
          },
          (error) => {
            console.log(error);
          }
        )
        .then(
          () => {
            if (this.hasData) {
              console.log(this.updrs_data);
              this.barPlot1('Part 1')
              this.barPlot2('Part 2')
              this.barPlot3('Part 3')
              this.barPlot4('Part 4')
            }
          },
          () => {}
        );
    },

    barPlot1: function (part) {
      if (this.plot_1 != null) {
        this.plot_1.destroy()
      }
      let label = 'Teil 1'
      let score = this.extractScore(part)
      var data = {
        labels: [label],
        datasets: [{
          axis: 'y',
          data: [score],
          backgroundColor: this.getBarColor(part, score)
        }]
      }

      var ctx_bar = this.$refs["part_1"]
          .querySelector("canvas")
          .getContext("2d");


      this.plot_1 = new Chart(ctx_bar, {
        type: "bar",
        data: data,
        options: { indexAxis: 'y', scales: { x: { max: 52 } }, plugins: { legend: { display: false } } },
      });
    },

    barPlot2: function (part) {
      if (this.plot_2 != null) {
        this.plot_2.destroy()
      }
      let label = 'Teil 2'
      let score = this.extractScore(part)
      var data = {
        labels: [label],
        datasets: [{
          axis: 'y',
          data: [score],
          backgroundColor: this.getBarColor(part, score)
        }]
      }

      var ctx_bar = this.$refs["part_2"]
          .querySelector("canvas")
          .getContext("2d");


      this.plot_2 = new Chart(ctx_bar, {
        type: "bar",
        data: data,
        options: { indexAxis: 'y', scales: { x: { max: 52 } }, plugins: { legend: { display: false } } },
      });
    },

    barPlot3: function (part) {
      if (this.plot_3 != null) {
        this.plot_3.destroy()
      }
      let label = 'Teil 3'
      let score = this.extractScore(part)
      var data = {
        labels: [label],
        datasets: [{
          axis: 'y',
          data: [score],
          backgroundColor: this.getBarColor(part, score)
        }]
      }

      var ctx_bar = this.$refs["part_3"]
          .querySelector("canvas")
          .getContext("2d");


      this.plot_3 = new Chart(ctx_bar, {
        type: "bar",
        data: data,
        options: { indexAxis: 'y', scales: { x: { max: 132 } }, plugins: { legend: { display: false } } },
      });
    },

    barPlot4: function (part) {
      if (this.plot_4 != null) {
        this.plot_4.destroy()
      }
      let label = 'Teil 4'
      let score = this.extractScore(part)
      var data = {
        labels: [label],
        datasets: [{
          axis: 'y',
          data: [score],
          backgroundColor: this.getBarColor(part, score)
        }]
      }

      var ctx_bar = this.$refs["part_4"]
          .querySelector("canvas")
          .getContext("2d");


      this.plot_4 = new Chart(ctx_bar, {
        type: "bar",
        data: data,
        options: { indexAxis: 'y', scales: { x: { max: 25 } }, plugins: { legend: { display: false } } },
      });
    },

    extractScore: function (part) {
      return this.updrs_data[0]["score"][part];
    },

    switchedTo: function () {
      this.getUPDRSData();
    },

    getBarColor: function(text, score) {
      if (text === 'Part 1') {
        if (score <= 10) {
          return '#008000'
        } else if (score > 10 && score <= 21) {
          return '#FDBC40'
        } else {
          return '#FF0000'
        }
      } else if (text === 'Part 2') {
        if (score <= 12) {
          return '#008000'
        } else if (score > 13 && score <= 29) {
          return '#FDBC40'
        } else {
          return '#FF0000'
        }
      } else if (text === 'Part 3') {
        if (score === 0) {
          return '#008000'
        } else if (score > 0 && score <= 32) {
          return '#FDBC40'
        } else {
          return '#FF0000'
        }
      } else if (text === 'Part 4') {
        if (score <= 0) {
          return '#008000'
        } else if (score > 0 && score <= 4) {
          return '#FDBC40'
        } else {
          return '#FF0000'
        }
      } else {
        return
      }
    },
  },

  mounted() {
    this.patient_id = this.$route.params.id.split("&", 1)[0];
    this.getUPDRSData();
  },

  expose: ["switchedTo"],
};
</script>
