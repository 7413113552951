<template>
  <div :key="componentKey">
    <v-row v-if="store.idList.length > 0" class="mb-2">
      <!-- Left Column -->
      <v-col cols="6">
        <v-row>
          <!-- Content for the left column, row 1 -->
          <v-col>
            <v-btn
              class="align left"
              v-if="(currentPatientIndex > 0) & (store.idList.length > 0)"
              @click="goToPrevPatient"
              >Vorheriger Patient</v-btn
            >
          </v-col>
        </v-row>
      </v-col>

      <!-- Right Column (Spanning both rows) -->
      <v-col cols="6" class="d-flex align-center justify-end">
        <v-btn
          class="align right"
          v-if="
            (currentPatientIndex < store.idList.length - 1) &
            (store.idList.length > 0)
          "
          @click="goToNextPatient"
          >Nächster Patient</v-btn
        >
      </v-col>
    </v-row>
    <v-card v-if="!helpers.isEmpty(patient) & !loading">
      <v-card-item>
        <v-row>
          <!-- Left Column -->
          <v-col cols="8">
            <v-row>
              <!-- Left Column -->
              <v-col cols="12">
                <v-row>
                  <!-- Content for the left column, row 1 -->
                  <v-col>
                    <v-card-title
                      ><span class="text-h5"
                        >{{ patient.first_name + " " + patient.last_name }},
                        {{ patient.patient_id }}</span
                      ></v-card-title
                    >
                    <!-- Content for the left column, row 2 -->
                    <v-card-subtitle
                      >Geburtsdatum: {{ birthDate_print }} (Alter:
                      {{ agePrint }}),
                      <span v-if="patient.diagnosis_time">
                        Parkinson seit:
                        {{ patient.diagnosis_time }}
                      </span>
                      <span v-if="patient.diagnosis_time && patient.insurer"
                        >,
                      </span>
                      <span v-if="patient.insurer">
                        Kostenträger:
                        {{ patient.insurer }}
                      </span>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <!-- Right Column (Spanning both rows) -->
          <v-col
            cols="4"
            class="d-flex align-center justify-end"
            v-if="patient.activ | !loading"
          >
            <AlertSystem
              :height="35"
              :patient_id="patient.patient_id"
              :display-all="false"
              :key="patient.patient_id"
            />
          </v-col>
        </v-row>
      </v-card-item>
    </v-card>
    <v-tabs v-model="tab" bg-color="grey" color="#042940" @click="tabChanged">
      <v-tab value="overview_view">Übersicht</v-tab>
      <v-tab value="sensors_view">Sensordaten</v-tab>
      <v-tab value="treatment_doc_view">Versorgungsdokumentation</v-tab>
      <v-tab value="medication_plan_view">Medikationsdaten</v-tab>
      <v-tab value="questionnaires_scores_view">Fragebögen & Scores</v-tab>
      <v-tab value="diary_view_who5">WHO-5 Ergebnisse</v-tab>
      <!-- <v-tab value="wellbeeing_view">Wohlbefinden</v-tab> -->
    </v-tabs>

    <v-card-text v-if="!loading">
      <v-window v-model="tab">
        <v-window-item value="overview_view">
          <PatientOverview ref="overview" />
        </v-window-item>

        <v-window-item value="sensors_view">
          <SensorView />
        </v-window-item>

        <v-window-item value="treatment_doc_view">
          <TreatmentDocumentation ref="treatment_doc" />
        </v-window-item>

        <v-window-item value="medication_plan_view">
          <MedicationPlan ref="medication_plan" />
        </v-window-item>

        <v-window-item value="questionnaires_scores_view">
          <QuestionnaireScoresView ref="questionnaire_scores" />
        </v-window-item>

        <v-window-item value="diary_view_who5">
          <DiaryView ref="diary_who5" />
          <!-- <component ref="diary" :is="DiaryView"></component> -->
        </v-window-item>

        <!-- <v-window-item value="wellbeeing_view">
                        <WellbeeingView ref="wellbeeing" />
                    </v-window-item> -->
      </v-window>
    </v-card-text>
  </div>
</template>

<script>
import PatientOverview from "./PatientOverview.vue";
import TreatmentDocumentation from "./TreatmentDocumentation.vue";
import AlertSystem from "../alertsystem/AlertSystem.vue";
import SensorView from "../sensors/SensorView.vue";
import MedicationPlan from "../medication/MedicationPlan.vue";
import QuestionnaireScoresView from "./QuestionnaireScoresView.vue";
import DiaryView from "./DiaryView.vue";
import { store } from "@/composable/id-list.js";

export default {
  props: ["id", "view"],

  name: "PatientList",

  components: {
    PatientOverview,
    TreatmentDocumentation,
    AlertSystem,
    SensorView,
    MedicationPlan,
    QuestionnaireScoresView,
    DiaryView,
  },

  setup() {
    return { store };
  },

  data: () => ({
    patient: {},
    tab: "",
    componentKey: "",
    loading: true,
  }),

  methods: {
    fetchPatientData: function () {
      console.log(this.id);
      this.$axios.get("/get_patient/" + this.id).then(
        (response) => {
          if (response.data == {}) {
            return;
          }

          console.log(response);

          this.patient = response.data;
          this.loading = false;
        },
        () => {}
      );
    },

    tabChanged: function () {
      if (this.view != this.tab) {
        this.$router.push({
          name: "patient-view",
          params: { id: this.patient.patient_id, view: this.tab },
        });
      }

      if (this.tab == "treatment_doc_view") {
        console.log(this.$refs);
        this.$refs.treatment_doc.switchedTo();
      } else if (this.tab == "overview_view") {
        console.log(this.$refs);
        this.$refs.overview.switchedTo();
      }
    },

    goToPrevPatient() {
      this.loading = true;
      const prevPatientId = this.store.idList[this.currentPatientIndex - 1];
      this.$router.push({
        name: "patient-view",
        params: { id: prevPatientId },
      });
    },
    goToNextPatient() {
      this.loading = true;
      const nextPatientId = this.store.idList[this.currentPatientIndex + 1];
      this.$router.push({
        name: "patient-view",
        params: { id: nextPatientId },
      });
    },
  },

  computed: {
    birthDate_print: function () {
      let date = new Date(this.patient.birth_date);

      console.log(this.patient.birth_date);

      let formatted_time = date.toLocaleString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      });

      return formatted_time;
    },
    agePrint: function () {
      let today = new Date();
      let date = new Date(this.patient.birth_date);

      var ageDate = new Date(today - date); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    currentPatientIndex() {
      return this.store.idList.indexOf(this.id);
    },
  },

  watch: {
    "$route.params.id": function (newId) {
      this.componentKey = newId;
      this.fetchPatientData();
    },
    "$route.params.view": function (newView) {
      let decodedNewView = decodeURIComponent(newView);
      this.tab = decodedNewView.split("&")[0];
    },
  },

  mounted() {
    this.tab = this.$route.params.view.split("&")[0];
    this.componentKey = this.$route.params.id;
    this.fetchPatientData();
    console.log(this.store.idList);
  },
};
</script>
