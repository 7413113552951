<script setup>
import HomeVisit from "./form/HomeVisit.vue";
import CareCouncil from "./form/CareCouncil.vue";
import PatientBriefing from "./form/PatientBriefing.vue";
import InitialExam from "./form/InitialExam.vue";
import DeviceIntroduction from "./form/DeviceIntroduction.vue";
import DataRating from "./form/DataRating.vue";
import PatientCall from "./form/PatientCall.vue";
import CaseDiscussion from "./form/CaseDiscussion.vue";
import CaseDiscussionDoctor from "./form/CaseDiscussionDoctor.vue";
import PatientCouncil from "./form/PatientCouncil.vue";
import FeedbackCareTeam from "./form/FeedbackCareTeam.vue";
import OtherDocumentation from "./form/OtherDocumentation.vue";
import VideoConsultation from "./form/VideoConsultation.vue";
import NursePatientCall from "./form/NursePatientCall.vue";
import StudyExclusion from "./form/StudyExclusion.vue";
</script>

<template>
  <v-container>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-row>
          <v-btn color="btn btn-primary" v-bind="props" prepend-icon="mdi-plus">
            Dokumentation hinzufügen
          </v-btn>
        </v-row>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in list_of_doctypes"
          :key="index"
          :value="index"
        >
          <v-list-item-title
            @click="
              (dialog = true),
                (currentForm = item.template),
                (documentType = item.type),
                (title = item.name)
            "
            >{{ item.name }}</v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-menu>

    <v-row cols>
      <v-col cols="3" class="text-left"> Dokumententyp </v-col>
      <v-col cols="3" class="text-left"> Datum der Maßnahme </v-col>
      <v-col cols="3" class="text-left ml-10"> Dokumentierender Nutzer </v-col>
    </v-row>
    <div v-for="documentation in documentations" :key="documentation._id">
      <!-- <v-row>
                <v-col>
                    <h5 class="mb-n2 mt-2">{{ Object.values(list_of_doctypes).find(item => item.type ==
                        key).name }}
                    </h5>
                </v-col>
            </v-row> -->
      <v-expansion-panels variant="accordion">
        <v-col>
          <v-row class="mb-0">
            <v-expansion-panel>
              <v-expansion-panel-title>
                <v-col cols="3" class="text-left">{{
                  documentation.title
                }}</v-col>
                <v-col cols="3" class="text-left">{{
                  helpers.formatDateStringToGermanDate(
                    documentation.treatment_date
                  )
                }}</v-col>
                <v-col cols="3" class="text-left">{{
                  documentation.user_name_display
                }}</v-col>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <DeviceIntroduction
                  v-if="documentation.treamtent_type == 'device_intro'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <InitialExam
                  v-if="documentation.treamtent_type == 'init_exam'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <HomeVisit
                  v-if="documentation.treamtent_type == 'home_visit'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <CareCouncil
                  v-if="documentation.treamtent_type == 'care_council'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <PatientBriefing
                  v-if="documentation.treamtent_type == 'patient_briefing'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <DataRating
                  v-if="documentation.treamtent_type == 'data_rating'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <PatientCall
                  v-if="documentation.treamtent_type == 'patient_call'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <NursePatientCall
                  v-if="documentation.treamtent_type == 'nurse_patient_call'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <CaseDiscussion
                  v-if="documentation.treamtent_type == 'case_discussion'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <CaseDiscussionDoctor
                  v-if="
                    documentation.treamtent_type == 'case_discussion_doctor'
                  "
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <PatientCouncil
                  v-if="documentation.treamtent_type == 'patient_council'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <FeedbackCareTeam
                  v-if="documentation.treamtent_type == 'feedback_careteam'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <VideoConsultation
                  v-if="documentation.treamtent_type == 'video_consultation'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <OtherDocumentation
                  v-if="documentation.treamtent_type == 'other_documentation'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
                <StudyExclusion
                  v-if="documentation.treamtent_type == 'study_exclusion'"
                  :document_data="documentation"
                  :disabled_field="true"
                />
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-row>
        </v-col>
      </v-expansion-panels>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent width="1024">
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <component
                v-on:close-dialog="closeDialog"
                :is="currentForm"
                v-if="documentType == 'device_intro'"
                v-bind="{ init_exam: getDocumentsByType('init_exam') }"
              >
              </component>
              <component
                v-on:close-dialog="closeDialog"
                :is="currentForm"
                v-if="documentType != 'device_intro'"
              >
              </component>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green-darken-1"
              variant="text"
              @click="dialog = false"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "TreatmentDocumentation",

  data: () => ({
    documentations: [],
    dialog: false,
    list_of_doctypes: [
      {
        type: "init_exam",
        name: "Eingangsuntersuchung in der Praxis/ Klinik",
        template: InitialExam,
      },
      {
        type: "device_intro",
        name: "Instruktion in Wearable und App",
        template: DeviceIntroduction,
      },
      {
        type: "patient_briefing",
        name: "Telefonisches Eingangsgespräch Telenurse - Patient",
        template: PatientBriefing,
      },
      {
        type: "data_rating",
        name: "Datensichtung, Beurteilung & Aufbereitung",
        template: DataRating,
      },
      {
        type: "patient_call",
        name: "Kontakt Telenurse - Patient",
        template: PatientCall,
      },
      {
        type: "nurse_patient_call",
        name: "Telefonat Parkinson Nurse - Patient",
        template: NursePatientCall,
      },
      {
        type: "care_council",
        name: "Fallbesprechung im Pflegekonsil",
        template: CareCouncil,
      },
      {
        type: "home_visit",
        name: "Hausbesuch durch Pflegedienst",
        template: HomeVisit,
      },
      {
        type: "case_discussion",
        name: "Fallbesprechung im Konsil",
        template: CaseDiscussion,
      }, // gerade in Absprache, ob noch aktuell
      {
        type: "case_discussion_doctor",
        name: "Fallbesprechung Telenurse - Fachärzt*in",
        template: CaseDiscussionDoctor,
      },
      {
        type: "patient_council",
        name: "Telefonat Facharzt - Patient*in",
        template: PatientCouncil,
      },
      {
        type: "feedback_careteam",
        name: "Rückmeldung an das Versorgungsteam nach telefonischer Konsultation",
        template: FeedbackCareTeam,
      }, // laut Anforderungen nicht mehr aktuell, TODO: bitte entsprechend alles rauslöschen, wenn möglich
      {
        type: "video_consulttion",
        name: "Videokonsultation Facharzt - Patient",
        template: VideoConsultation,
      },
      {
        type: "other_documentation",
        name: "sonstige Dokumentation",
        template: OtherDocumentation,
      },
      {
        type: "study_exclusion",
        name: "Studienausschluss",
        template: StudyExclusion,
      }
    ],
  }),

  methods: {
    fetchDocumentationData: function () {
      let patient_id = this.$route.params.id.split("&", 1);

      this.$axios
        .get("/get_treatment_doc_patient/" + patient_id)
        .then(
          (response) => {
            this.documentations = response.data;
            if (this.documentations != {}) {
              this.documentations = Object.values(this.documentations).sort(
                function (a, b) {
                  return (
                    new Date(b.treatment_date) - new Date(a.treatment_date) ||
                    new Date(b.documentation_date) -
                      new Date(a.documentation_date)
                  );
                }
              );
            }
            console.log(this.documentations);
          },
          () => {}
        )
        .then(() => {
          console.log(this.treatmentDocumentationGrouped);
        });
    },

    getDocumentsByType: function (document_type) {
      let documents = this.documentations.find(
        (item) => item.treamtent_type === document_type
      );
      return documents;
    },

    closeDialog: function () {
      this.dialog = false;
      this.fetchDocumentationData();
    },

    switchedTo: function () {
      this.fetchDocumentationData();
    },
  },

  computed: {
    treatmentDocumentationGrouped: function () {
      const groupedData = this.documentations.reduce((acc, item) => {
        // Extract the date portion without the time
        const treamtent_type = item.treamtent_type;

        // Check if the date exists in the accumulator
        if (!acc[treamtent_type]) {
          acc[treamtent_type] = [];
        }

        // Push the item to the corresponding date
        acc[treamtent_type].push(item);

        return acc;
      }, {});

      this.helpers
        .getUserInfoForAllUsers(this.keycloak.token)
        .then((user_data) => {
          console.log(groupedData);
          Object.values(groupedData).forEach((group) => {
            group.forEach((element) => {
              element.user_name_display = user_data
                ? Object.values(user_data).find(
                    (item) => item.user_id === element.user_id
                  ).first_name +
                  " " +
                  Object.values(user_data).find(
                    (item) => item.user_id === element.user_id
                  ).last_name
                : "";
            });
          });
        });

      console.log(groupedData);

      return groupedData;
    },
  },

  mounted() {
    this.fetchDocumentationData();
  },
  expose: ["switchedTo"],
};
</script>
