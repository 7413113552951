<template>
  <v-container>
    <v-form ref="form" :disabled="submitted" fast-fail @submit.prevent="addDocumentation">
      <div v-if="dateMsg">
        <p class="text-red">Bitte verwenden Sie die Kalenderfunktion zur Eingabe eines Datums</p>
      </div>
      <v-row>
        <v-col cols="3">
          <v-text-field
            :readonly="disabled || init_exam"
            :rules="dateRule"
            v-model="dateOfVisit"
            type="date"
            :max="new Date().toISOString().slice(0, -14)"
            label="Datum der Eingangsuntersuchung"
            @keydown="clearInput()"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-text-field
        :readonly="disabled"
        v-model="duration"
        :rules="durationRule"
        label="Dauer der Einweisung (HH:MM)"
      ></v-text-field>

      <v-label class="align-left">Studienzentrum</v-label>
      <v-radio-group :readonly="disabled || init_exam" v-model="studyCenter">
        <v-radio label="Marburg" value="MR"></v-radio>
        <v-radio label="Hamburg" value="HH"></v-radio>
      </v-radio-group>

      <v-checkbox
        :readonly="disabled"
        v-model="further.documentation.status"
        label="Ich möchte etwas dokumentieren, was nicht zu einer der vorhandenen Positionen passt"
      ></v-checkbox>
      <v-textarea
        v-show="further.documentation.status"
        :readonly="disabled"
        v-model="further.documentation.content"
        label="sonstige Dokumentation/en"
      ></v-textarea>

      <v-btn v-show="!disabled" type="submit" block class="mt-2">Submit</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import VueJWTDecode from "vue-jwt-decode";

export default {
  name: "DeviceIntroduction",

  props: {
    document_data: Object,
    init_exam: Object,
    disabled_field: Boolean,
  },

  data: function () {
    return {
      title: "Instruktion in Wearable und App",
      type: "device_intro",
      dateOfVisit: this.document_data
        ? this.document_data.treatment_date
        : this.init_exam
          ? this.init_exam.treatment_date
          : "",
      further: this.document_data
        ? this.document_data.content.find((item) => item.property === "further")
            .value
        : {
            documentation: { status: false, content: "" },
          },
      duration: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "duration",
          ).value
        : "",
      durationRule: [
        (value) => {
          if (/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value))
            return true;

          return "Dauer muss im Format HH:MM angegeben werden";
        },
      ],
      studyCenter: this.document_data
        ? this.document_data.content.find(
            (item) => item.property === "study_center",
          ).value
        : this.init_exam
          ? this.init_exam.content.find(
              (item) => item.property === "study_center",
            ).value
          : "",
      billableProcedures: ["A02"],
      disabled: this.disabled_field,
      notEmptyRule: [
        (value) => {
          if (value) return true;

          return "Dies ist ein Pflichtfeld";
        },
      ],
      dateRule: [
        (value) => {
          if (value) {
            this.dateMsg = false;
            return true;
          }

          return "Die Datumseingabe ist ein Pflichtfeld";
        },
      ],
      submitted: false,
      dateMsg: false,
    };
  },

  methods: {
    addDocumentation: function () {
      this.$refs.form.validate().then(
        (status) => {
          if (status.valid === true) {
            this.submitted = true
            const token = this.keycloak.token;
            const decoded_token = VueJWTDecode.decode(token);
            let user_name = decoded_token.preferred_username;

            let patient_id = this.$route.params.id.split("&", 1)[0];
            console.log(user_name);
            console.log(patient_id);

            let json_payload = {
              user_id: user_name,
              patient_id: patient_id,
              treatment_date: this.dateOfVisit,
              documentation_date: new Date().toISOString(),
              treamtent_type: this.type,
              billable_procedures: this.billableProcedures,
              title: this.title,
              content: [
                { property: "duration", value: this.duration },
                { property: "study_center", value: this.studyCenter },
                { property: "further", value: this.further },
              ],
            };

            this.$axios
              .post("/treatment_documentation/", json_payload, {
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then(
                () => {
                  console.log("Patient created");
                  this.$emit("close-dialog");
                },
                () => {
                  console.log("Patient not created");
                },
              );
          }
        },
        () => {},
      );
    },

    clearInput: function() {
      this.dateOfVisit = ""
      this.dateMsg = true
    }
  },

  mounted() {
    console.log(this.disabled);
  },
};
</script>
