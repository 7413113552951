import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
// import axios from "axios";
import Keycloak from "keycloak-js";
import { createRouter, createWebHashHistory } from "vue-router";
import PatientList from "./components/patients/PatientList.vue";
import CreatePatient from "./components/patients/CreatePatient.vue";
import PatientUpdate from "./components/patient/PatientUpdate.vue";
import PatientView from "./components/patient/PatientView.vue";
import DataExport from "./components/data/DataExport.vue";
import utilities from "./components/helpers/helpers";
import axios from "axios";

loadFonts();

import VueJWTDecode from "vue-jwt-decode";

const routes = [
  {
    path: "/patients/:active",
    name: "patient-list",
    component: PatientList,
    props: true,
  },
  { path: "/patients/create", 
    name: "create-patient",
    component: CreatePatient 
  },
  {
    path: "/patients/update/:id",
    name: "patient-update",
    component: PatientUpdate,
  },
  {
    path: "/patient/:id/:view",
    name: "patient-view",
    component: PatientView,
    props: true,
  },
  { path: "/data/export", 
    name: "data-export",
    component: DataExport 
  },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach((to, from, next) => {
  // Check if the route being navigated to is the default route
  if (to.name == undefined) {
    // Redirect to the patient-list route
    next({ name: "patient-list", params: { active: true } });
  } else {
    // Allow the navigation to proceed as normal
    next();
  }
});

let initOptions = {
  url: process.env.VUE_APP_KEYCLOAK_HOST,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
  onLoad: "login-required",
  flow: "standard",
  checkLoginIframe: false,
};

let _keycloak = new Keycloak();

let _axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL, // Your API base URL
});

let safari = /.*Version.*Safari.*/.test(navigator.userAgent);

if (safari) {
  alert(
    "Safari wird aktuell leider nicht unterstützt. Bitte nutzen Sie Firefox oder Google Chrome",
  );
} else {
  _keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((authenticated) => {
      if (!authenticated) {
        window.location.reload();
      } else {
        let app = createApp(App);
        app.config.globalProperties.keycloak = _keycloak; // Globale Variable
        app.config.globalProperties.helpers = utilities;
        app.use(vuetify).use(router).mount("#app");

        let token = _keycloak.token;
        console.log(token);
        const decoded_token = VueJWTDecode.decode(token);
        let first_name = decoded_token.given_name;
        let last_name = decoded_token.family_name;
        let user_name = decoded_token.preferred_username;
        let roles = decoded_token.resource_access.ppr.roles;

        _axiosInstance.defaults.headers.common["Authorization"] =
          "Bearer " + _keycloak.token;

        console.log(_axiosInstance.defaults);

        app.config.globalProperties.$axios = _axiosInstance;
        app.config.productionTip = process.env.VUE_APP_ENABLE_CONSOLE_LOGS === 'true'

        if (roles) {
          _axiosInstance.post("/users/", {
            user_id: user_name,
            first_name: first_name,
            last_name: last_name,
            roles: roles,
          });
        }
      }

      // const decoded_token = VueJWTDecode.decode(keycloak.token);
      // const roles = decoded_token.realm_access.roles;
      // const firstname = decoded_token.given_name;
      // const lastname = decoded_token.family_name;

      //Token Refresh
      setInterval(() => {
        _keycloak
          .updateToken(70)
          .then((refreshed) => {
            if (refreshed) {
              console.log("Token refreshed" + refreshed);
              _axiosInstance.defaults.headers.common["Authorization"] =
                "Bearer " + _keycloak.token;
            } else {
              console.log(
                "Token not refreshed, valid for " +
                  Math.round(
                    _keycloak.tokenParsed.exp +
                      _keycloak.timeSkew -
                      new Date().getTime() / 1000,
                  ) +
                  " seconds",
              );
            }
          })
          .catch(() => {
            console.log("Failed to refresh token");
          });
      }, 6000);
    })
    .catch((error) => {
      console.log(error);
    });
}
