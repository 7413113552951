<template>
  <v-sheet
    border="md"
    rounded="lg"
    width="100%"
    class="my-sheet pa-4 text-left mx-auto mb-2 mr-2"
  >
    <div
      v-for="(diary_entry, key) in diaryEntriesReversed.slice(0, 1)"
      :key="key"
    >
      <v-expansion-panels variant="accordion">
        <v-expansion-panel>
          <v-expansion-panel-title>
            WHO-5 vom
            {{ helpers.formatDateStringToGermanDate(diary_entry.authored) }}
          </v-expansion-panel-title>

          <v-expansion-panel-text>
            <div v-for="(q_a, q_key) in diary_entry.item" :key="q_key">
              <div>
                <div v-if="checkIfArrayIncludes(q_a.answer[0], 'valueBoolean')">
                  {{ q_a.text }}:
                  <p v-if="q_a.answer[0].valueBoolean === true">Ja</p>
                  <p v-else>Nein</p>
                </div>
                <div
                  v-else-if="
                    checkIfArrayIncludes(q_a.answer[0], 'valueDecimal')
                  "
                >
                  {{ q_a.answer[0].valueDecimal }} {{ q_a.text }}
                </div>
                <div
                  v-else-if="checkIfArrayIncludes(q_a.answer[0], 'valueCoding')"
                >
                  {{ q_a.answer[0].valueCoding.display }} {{ q_a.text }}
                </div>
                <div
                  v-else-if="checkIfArrayIncludes(q_a.answer[0], 'valueTime')"
                >
                  {{ q_a.answer[0].valueTime }} {{ q_a.text }}
                </div>
                <div
                  v-else-if="checkIfArrayIncludes(q_a.answer[0], 'valueString')"
                >
                  {{ q_a.answer[0].valueString }} {{ q_a.text }}
                </div>
              </div>
            </div>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <div v-if="diary_entries.length === 0">
      Keine Tagebucheinträge vorhanden
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: "DiaryViewPV",

  data: () => ({
    diary_entries: [],
    dialog: false,
  }),

  methods: {
    getDiaryResponses: function () {
      let patient_id = this.$route.params.id.split("&", 1);

      this.$axios
        .get("/get_diary_responses/" + patient_id)
        .then(
          (response) => {
            this.diary_entries = response.data;
            console.log(this.diary_entries);
          },
          () => {}
        )
        .then(() => {
          console.log(this.treatmentDocumentationGrouped);
        });
    },

    closeDialog: function () {
      this.dialog = false;
      this.getDiaryResponses();
    },

    switchedTo: function () {
      this.getDiaryResponses();
    },

    checkIfArrayIncludes: function (item, itemToCheck) {
      return Object.prototype.hasOwnProperty.call(item, itemToCheck);
    },
  },

  computed: {
    diaryEntriesReversed: function () {
      return this.diary_entries
        .slice(0)
        .sort(function (a, b) {
          return new Date(a.authored) - new Date(b.authored);
        })
        .reverse();
    },
  },

  mounted() {
    this.getDiaryResponses();
  },
  expose: ["switchedTo"],
};
</script>
